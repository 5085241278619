footer {
    height: 50px;
    width: 100%;
    background: black;
    color: white;
    box-shadow: 0px -2px 5px black;
    border-top: 3px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trademark {
    height: fit-content;
    width: fit-content;
    font-size: 12px;
}

.logo-pic {
    width: 25px;
    height: 25px;
    margin-left: 20px;
}