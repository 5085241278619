.navbar{
    width: 350px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.navbar-item {
    width: 25%;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    transition: all .1s ease-out;    
    text-decoration: none;
    color: white;
}

@media (height < 350px){
    
    .navbar {
        justify-content: flex-end;
    }
    
    .navbar-item {
        font-size: 12px;
        width: fit-content;
        margin-left: 2px;
    }
}

@media (width < 650px){
    .navbar {
        justify-content: center;
    }
    
    .navbar-item {
        font-size: 12px;
        width: fit-content;
        margin-left: 2px;
    }
}