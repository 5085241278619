/*Utility css*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

/*Disallows selection of text*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif;
}

/* Create a page break/underline */
.lineBreak {
  width: 90%;
  height: 3px;
  background-color: white;
  margin: 0 auto 15px auto;
}

.resumeCard {
    height: fit-content;
    width: 800px;
    min-width: 625px;
}

.rightAside {
    width: 285px;
    min-width: 285px;
    height: fit-content;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--headerBackgroundColor);
    box-shadow: inset 0px 0px 5px rgb(56, 56, 56);
    margin: 0 10px 0 0;
    border-radius: 5px;
    position: sticky;
    top: 20px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--backgroundColor);
}


.infoWrapper {
    height: var(--headerHeight);
    width: 60%;
    background-color: var(--textBackgroundColor);
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px 0px 30px;
    border-radius: 10px;
    box-shadow: -2px 2px 5px rgb(0, 0, 0);
}

.headerName {
    font-size: var(--nameFontSize);   
}

.headerNameText {
    font-size: calc(var(--nameFontSize) * .6);
    padding-left: 20px;
}

.headerLocation {
    font-size: calc(var(--nameFontSize) * .6);
}

.headerJobTitle {
    padding-top: 10px;
    font-size: calc(var(--nameFontSize) * .5);
    color: black;
    font-family: 'Calibri', 'Times New Roman', Times, serif;
}

.buttonWrapper {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
}

.button {
    width: fit-content;
    height: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-right: 20px;
    padding: 0px 10px;
    box-shadow: -2px 2px 5px rgb(0, 0, 0);
}

.button:hover {
    cursor: pointer;
}

.bodyTitle {
     margin-bottom: 3px;
}

.bodyText{
    font-size: 12px;
    margin-bottom: 10px;
}

.job {
    width: 90%;
    margin: 0px auto 30px auto;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--headerBackgroundColor);
    border: 5px solid white;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgb(56, 56, 56);
}

.jobHeader {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px;
}

.companyDetails {
    width: 100%;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px 5px 2px;
}

.companyName {
    font-size: 16px;
    margin-bottom: 2px;
    margin-right: auto;
    font-family: 'Calibri', 'Times New Roman', Times, serif;
}

.companyLogo {
    width: auto;
    height: 40px;
    border: 5px transparent solid;
    border-radius: 3px;
}

.expandAll {
    font-size: 12px;
    cursor: pointer;
}

.datesOfEmployment {
    font-size: 14px;
    text-align: right;
}

.jobTitle {
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bubbleWrapper {
    width: fit-content;
    display: inherit;
    flex-direction: inherit;
    justify-content: right;
    padding-right: 20px;
}

.bubble {
    width: fit-content;
    height: 1.2em;
    color: purple;
    background-color: white;
    border-radius: 5px;
    margin-left: 8px;
}

.invertJobTitle {
    background-color: white;
    color: black;
}

.allRoles {
    width: 95%;
    margin: 0 auto;
    padding-top: 5px;
    height: fit-content;
    background-color: var(--textBackgroundColor);
    /* background-color: rgb(219, 219, 219); */
    border-radius: 5px;
}

.role{
    color: rgba(0,0,0, .8);
}

.role:hover {
    font-weight: bold;
    margin-left: -2px;
}

.toggle-button{
    width: 70px;
    height: 1.5em;
    border-radius: 3px;
    padding: 1px;
    font-size: 12px;
    cursor: pointer;
}

.jobDuties {
    width: 95%;
    background-color: white;
    color:rgb(36, 36, 36);
    padding: 7px 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    /* border: 2px solid rgb(85, 122, 149); */
    border-radius: 5px;
    margin: 5px auto 10px auto;
    box-shadow: -2px 2px 8px rgb(56, 56, 56);
}

.jobDuties > li {
    width: 100%;
    padding: 3px 10px;
    list-style: none;
    font-size: 14px;
    padding-bottom: 5px;
    text-align: left;
}

.jobDuties > li:nth-child(even) {
    background:rgb(235, 235, 235);
}

.hideJobDuties {
  display: none;
}


.showJobDuties > li:before {
  content:"· ";
  font-size: 40px;
  vertical-align: middle;
  line-height: 12px;
  color:var(--backgroundColor);
}

.logoWrapper {
  margin: 0 auto;  
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (width < 600px){
    .companyDetails {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .jobHeader {
        flex-direction: column;
    }
}