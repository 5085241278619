.vertProjectContainer {
    width: 100%;
    min-height: 200px;
    height: calc(100vh - 150px);
    background-color: var(--backgroundColor);
    color: white;
    padding: 10px 25px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  /* General CSS */
:root {
  --border-radius: 5px;
}

/* Vertical Menu */
.vert-menu {
  height: fit-content;
  width: 95%;
  min-height: 700px;
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  border: 2px solid darkgray;
  border-radius: var(--border-radius);
  box-shadow: 4px 4px 6px rgba(255, 255, 255, .2);
  transition: all .1s linear;
}

.vert-card {
  height: 40px;
  width: 100%;
  padding: 3px;
  background: black;
  color: white;
  transition: all .5s ease-in;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  border-bottom: 2px solid #323232;
}

.vert-card:last-child {
  border-bottom: 0px solid #323232;
}

.vert-card:first-child {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.vert-card:last-child {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

/* Card content and divs */

.vertContent {
  height: 100%;
  width: 100%;
  display: none;
  opacity: 0;
  transition: all .5s cubic-bezier(1,-0.02,1,.69);
  justify-content: flex-start;
  align-items: flex-start;
}

.vertFacts {
  width: 100%;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.vertFactsList {
  width: 100%;
  height: fit-content;
  padding: 10px;
  list-style-type: none;
  background: var(--textBackgroundColor);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
  box-shadow: 2px 2px 4px black;
  opacity: .9;
  border: 3px solid black;
  border-radius: 5px;
}

.fact{
  width: 100%;
  height: fit-content;
  text-align: left;
  white-space: pre-wrap;
  opacity: 1;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Edge, Opera and Firefox */
}

#project-title {
  text-decoration: underline;
}

.firstWord {
  font-weight: bold;
}

.menuPic {
  max-width: 100%;
  object-fit: contain;
  object-position: top;
}

/* utility css classes */

.grow {
  background: #f0f2f5;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  cursor: default;
}

.hideNode {
  display: none;
}

.showNode {
  display: flex;
}

.fadeOutNode {
  opacity: 0;
}

.fadeInNode {
  opacity: 1;
}

@media (width < 800px) {
  .vertProjectContainer,
  .horizProjectContainer {
    overflow-y: auto;
  }
}

@media (width < 650px){
  .vertProjectContainer,
  .horizProjectContainer {
    height: calc(100vh - 100px);
  }
}

@media (height < 400px){
  .vertProjectContainer,
  .horizProjectContainer {
    height: calc(100vh - 100px);
  }
}