:root {
  --backgroundColor: #323232;
  --secondaryBackgroundColor: #5D5C61;
  --textBackgroundColor: rgb(243, 243, 243);
  --headerBackgroundColor: rgba(0,0,0,.4);
  --headerHeight: 150px;
  --nameFontSize: 24px;
  --titleFontColorA: white;
  --jobDutiesHover: #938e94;
  --amaranth-red: rgb(211, 47, 57);
  --faded-amaranth-red: rgb(211, 47, 57, .5);
}

/* CSS to prevent user selection of text */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Edge, Opera and Firefox */
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    background-color: var(--backgroundColor);
}

img, video {
    height: 100%;
    width: 100%;
}

/* Set body equal to 100vh and set overflow to auto so smaller screens can view content */
body {
    height: 100vh;
    background-color: var(--backgroundColor);
    color: white;
    overflow: auto;
}

.App {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.linebreak {
  width: 90%;
  height: 2px;
  box-shadow: 2px 2px 4px black;
  align-self: center;
  margin: 10px auto;
}

.aboutMeContainer {
    width: 100%;
    min-height: 200px;
    height: calc(100vh - 150px);
    padding: 50px 25px;
    background-color: var(--backgroundColor);
    color: white;
    overflow: hidden;
    display: flex;
    flex-wrap: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 25px;
  }

.profilePicture {
    width: 180px;
    height: 180px;
    flex-shrink: 0;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 3px 3px 6px black;
    transition: all .25s ease-out;
}

.profilePicture img {
  border-radius: inherit;
}

.aboutMeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutMeContent {
    background: var(--textBackgroundColor);
    color: black;
    height: fit-content;
    width: 80%;
    max-width: 950px;
    padding: 25px;
    border: 1px solid white;
    border-radius: 5px;
    transition: all .25s ease-out;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
}

.aboutMeContent h3 {
  width: fit-content;
  margin: 0 auto;
}

.backgroundList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  text-align: center;
}

.backgroundItem {
  padding: 5px 10px;
  width: fit-content;
  list-style-position: inside;
  border: 2px solid black;
  border-radius: 50px;
  background: var(--amaranth-red);
  color: white;
}

.contactMeContainer {
    width: 90%;
    min-height: 150px;
    height: calc(100vh - 150px);
    background-color: var(--backgroundColor);
    color: white;
    padding: 10px 25px;
    overflow: auto;
}

.contactForm {
  width: 60%;
  margin: 0 auto;
  transition: all .5s ease-out;
}

.contactForm div {
  width: 100%;
}

#email, #message {
  width: 100%;
}

@media (width < 700px) {
  .aboutMeContainer {
    height: calc(100vh - 100px);  
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .contactMeContainer {
    height: calc(100vh - 100px);
  }

  .profilePicture {
    width: 75px;
    height: 75px;
    margin-right: 25px;
    align-self: flex-end;
  }

  .aboutMeContent {
    width: 95%;
  }

  .contactForm {
    width: 100%;
  }
}

@media (height < 400px){
  .aboutMeContainer {
    height: calc(100vh - 100px);
  }

  .contactMeContainer {
    height: calc(100vh - 100px);
  }
}
