.jobContainer {
    width: 100%;
    min-height: 200px;
    height: calc(100vh - 150px);
    background-color: var(--backgroundColor);
    color: white;
    padding: 20px 25px;
    overflow: auto;
}

.jobNavBar {
    width: 90%;
    margin: 0 auto; 
    display: flex;
    justify-content: space-between;
}

.jobNavMenu {
    list-style-type: none;
    margin-bottom: 15px; 
    display: flex;
    gap: 20px;
}

.jobNavItem {
    cursor: pointer;
}

.devResumeContainer {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--headerBackgroundColor);
    border: 5px solid white;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgb(56, 56, 56);
    font-size: 18px;
    gap: 20px;
    color: rgb(220, 220, 220);
}

.devSkillsList {
    list-style-type: none;
}

.projectTable {
    border: 3px solid lightgray;
    box-shadow: 2px 2px 4px gray;
    padding: 3px;
    text-align: center;
}

.projectSubtitle {
    font-size: .9rem;
}

th, td {
    border: 1px solid white;
    padding: 1px;
}

.resumeLink {
    width: fit-content;
    color: white;
    margin-bottom: 5px;
    display: block;
    margin-left: auto;
}

.resumeLink:visited {
    color: red;
}

@media (width < 650px) {
    .jobContainer{
        height: calc(100vh - 100px);
    }
}

@media (height < 400px){
    .jobContainer {
      height: calc(100vh - 100px);
    }
}