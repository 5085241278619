header {
    width: 100%;
    height: 100px;
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background: hsla(356, 100%, 19%, 1);
    background: linear-gradient(45deg, hsla(356, 100%, 19%, 1) 0%, hsla(356, 65%, 51%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(356, 100%, 19%, 1) 0%, hsla(356, 65%, 51%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(356, 100%, 19%, 1) 0%, hsla(356, 65%, 51%, 1) 100%);
    box-shadow: 0px 2px 5px gray;
    transition: all .25s ease-in;
}

.header-title {
  font-style: italic;
}

@media (width < 650px){
    header {
      flex-direction: column;
      height: 50px;
      padding: 2px;
    }

    .header-title{
      font-size: 24px;
    }
}

@media (height < 350px){
  header {
    height: 50px;
  }

  .header-title{
    font-size: 24px;
  }
}